document.addEventListener('DOMContentLoaded', function () {
    const form = document.getElementById('contactForm');
    const submitButton = document.getElementById('submitButton');
    const loadingIndicator = document.getElementById('loadingIndicator');

    // Obtener el token CSRF y agregarlo al formulario
    // fetch('/csrf-token')
    //     .then(response => response.json())
    //     .then(data => {
    //         // Crear un campo oculto para el token CSRF
    //         const csrfInput = document.createElement('input');
    //         csrfInput.type = 'hidden';
    //         csrfInput.name = '_csrf';
    //         csrfInput.value = data.csrfToken;
    //         form.appendChild(csrfInput);
    //     })
    //     .catch(error => console.error('Error obteniendo el token CSRF:', error));

    form.addEventListener('submit', async function(event) {
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity()) {
            // Deshabilitar el botón y mostrar el indicador de carga
            submitButton.disabled = true;
            loadingIndicator.style.display = 'inline-block';

            const formData = {
                name: document.getElementById('nombre').value,
                email: document.getElementById('email').value,
                phone: document.getElementById('telefono').value,
                message: document.getElementById('mensaje').value
            };

            try {
                const response = await fetch('/send-email', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                        // 'x-csrf-token': form.querySelector('input[name="_csrf"]').value
                    },
                    body: JSON.stringify(formData)
                });

                const messageElement = document.getElementById('formResponse');
                if (response.ok) {
                    messageElement.innerHTML = '<div class="alert alert-success d-flex align-items-center" role="alert"><i class="bi bi-check-lg mx-2 fs-4"></i><div>Mensaje enviado exitosamente</div></div>';
                    form.reset(); // Limpiar el formulario
                    form.classList.remove('was-validated');
                } else {
                    messageElement.innerHTML = '<div class="alert alert-primary d-flex align-items-center" role="alert"><i class="bi bi-x-lg mx-2 fs-4"></i><div>Hubo un error al enviar el mensaje.</div></div>';
                }
            } catch (error) {
                console.error('Error:', error);
                document.getElementById('formResponse').innerHTML = '<div class="alert alert-primary d-flex align-items-center" role="alert"><i class="bi bi-x-lg mx-2 fs-4"></i><div>Hubo un error al enviar el mensaje.</div></div>';
            } finally {
                // Rehabilitar el botón y ocultar el indicador de carga
                submitButton.disabled = false;
                loadingIndicator.style.display = 'none';
            }
        } else {
            form.classList.add('was-validated');
        }
    }, false);
});
